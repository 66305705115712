<template>
  <div>
    <div class="w-100 my-1 mb-2 float-left pl-1">
      <div class="d-flex align-items-center float-left">
        <a class="mr-50" @click="$router.go(-1)">
          <feather-icon icon="ChevronLeftIcon" size="20" />
        </a>
        <h3 class="m-0 font-weight-600">
          {{ $t('tickets.title') }}
        </h3>
      </div>
    </div>

    <!-- Loading -->
    <div v-if="isLoading">
      <b-spinner type="grow" small class="mx-auto mt-3 d-block" />
    </div>

    <!-- Container with data -->
    <div v-else-if="items.length > 0" class="cards-container">
      <div>
        <!-- Items Loop -->
        <div
          v-for="(item, index) in items"
          :key="index"
          no-body
          class="mx-3 mb-2 p-0 d-inline-block"
        >
          <b-link :to="{ name: 'ticket', params: { id: id, ticketId: item.key } }">
            <div class="ticket">
              <div class="top">
                <div class="price-header">
                  <h2>{{ item.price / 100 }} €</h2>
                </div>
                <div class="mt-2 mb-2 ml-1 font-weight-bold">
                  <h3>{{ item.name[currentLocale] || Object.values(item.name)[0] }}</h3>
                </div>
                <div v-if="eventItem.createdBy" class="createdBy-long">
                  <safe-img class="logo badge-minimal" :src="eventItem.createdBy.logoURL" />
                  <div class="offered-by h-1r">
                    <p class="main">
                      {{ eventItem.createdBy.name }}
                    </p>
                  </div>
                </div>
                <div v-else-if="translate(eventItem.createdByCommunity)" class="createdBy-long">
                  <img class="logo badge-minimal" :src="eventItem.createdByCommunity.logoURL">
                  <div class="offered-by h-1r">
                    <p class="main">
                      {{ translate(eventItem.createdByCommunity.name) }}
                    </p>
                  </div>
                </div>
                <event-card-period
                  :event="eventItem"
                  tag="div"
                  class="bottom ml-1"
                  style="text-align: left !important;"
                />
              </div>
              <div class="rip" />
              <div class="bottom single-date">
                <b-button variant="primary" style="width: 80%;">
                  Comprar
                </b-button>
              </div>
            </div>
          </b-link>
        </div>
      </div>
      <div />

      <!-- Load More items -->
      <div v-if="items.length < itemsData.unpaginated.total" class="w-100 float-left">
        <b-button
          v-if="!isLoadingNextPage"
          class="center-x my-3"
          variant="outline-primary"
          @click="handleLoadOfNewItems"
        >
          {{ $t('action.load-more') }}
        </b-button>
        <div v-else class="w-100">
          <b-spinner
            class="center-x my-3"
            small
            variant="primary"
            type="grow"
            label="Loading..."
          />
        </div>
      </div>
    </div>

    <!-- Container without data: Placeholder -->
    <b-row v-else class="horizontal-placeholder">
      <b-col cols="12">
        <img :src="productsPlaceholder">
      </b-col>
      <b-col cols="12">
        <p class="text-primary">
          {{ $t('available.message', { itemName: 'items' }) }}
        </p>
      </b-col>
    </b-row>
    <!-- TODO: Create generic modal to request meeting out of members -->
  </div>
</template>
<script>
import Vue from 'vue';
import ProductsPlaceholder from '@/assets/images/placeholders/light/projects.svg';
import ProductPlaceholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import { getImageResource } from '@/@core/utils/image-utils';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import TicketPrice from './ticketPrice.vue';
import EventCardPeriod from '@/views/apps/events/components/EventCardPeriod.vue';

export default {
  name: 'Tickets',
  components: {
    TicketPrice,
    EventCardPeriod
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      productInput: {},
      formError: null,
      itemImage: null,
      imageSrc: null,
    };
  },
  computed: {
    token() {
      return Vue.$cookies.get('accessToken');
    },
    // Load data from store
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    itemsData() {
      return this.$store.getters.ticket;
    },
    eventItem() {
      return this.$store.getters.item('events', this.id);
    },
    items() {
      return this.itemsData.unpaginated;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    productPlaceholder() {
      return ProductPlaceholder;
    },
    productsPlaceholder() {
      return ProductsPlaceholder;
    },
  },
  watch: {
    itemImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData();
    this.fetchEvent();

    this.isLoading = false;
  },
  methods: {
    translate(field) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        field,
      );
    },
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // Call store action (with dispach) to load data from backend
    getImageSrc(url) {
      return getImageResource(url);
    },
    async fetchData() {
      await this.$store.dispatch('getItemsPayments', {
        itemType: 'ticket',
        eventKey: this.id,
      });
      this.isLoading = false;
    },

    async fetchEvent() {
      const response = await this.$store.dispatch('getItems', {
        itemType: 'events',
        requestConfig: {
          eventKeys: this.id,
          count: 1,
          offset: 0,
        },
      });
      this.isLoading = false;
    },

    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length < total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      }
    },

    async handleCreateItem(bvModalEvt) {
      // Name is required
      if (!this.productInput.name) {
        bvModalEvt.preventDefault();
        this.formError = false;
        return;
      }
      try {
        await this.$store.dispatch('createItem', {
          item: {
            itemType: 'products',
            requestConfig: {
              name: this.productInput.name,
              headline: this.productInput.headline,
              description: this.productInput.description,
            },
          },
          file: this.itemImage,
        });
        this.productInput = {};
        this.formError = null;
        this.itemImage = null;
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
  },
};
</script>
<style scoped>
/* TODO: fix responsive with bootstrap, better than media queries!! */
@media only screen and (max-width: 600px) {
  .cards-container {
    margin-left: -2rem;
  }
}

.price-header {
  width: 100%;
  background: #28aab8;
  background-color: #abe9cd;
  text-align: center !important;
  background-image: linear-gradient(315deg, #abe9cd 0%, #3eadcf 74%);
  padding-top: 100px;
  padding-bottom: 75px;
}

.price-header * {
  color: #fff;
  text-align: center !important;
  font-weight: bold;
}
.card-footer form {
  display: inline-block;
}
.redsys-button {
  background-color: #abe9cd;
  text-align: center !important;
  background-image: linear-gradient(315deg, #abe9cd 0%, #3eadcf 74%);
  border: 0px;
}
.button-transparent {
  background: transparent !important;
  border: 0px !important;
}
</style>
